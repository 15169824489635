* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.APP-color {
  color: rgb(34, 93, 153) !important;
}

.APP-background {
  background-color: rgb(34, 93, 153) !important;
}

/* width */
.w-100 {
  width: 100% !important;
}

.w-80 {
  width: 80% !important;
}

.w-95 {
  width: 95% !important;
}

.w-50 {
  width: 50%
}

/* border */
.border {
  border: 1px solid lightgray;
}

.border-darkblue {
  border: 1px solid gray;
}

.b-r-10 {
  border-radius: 10px;
}

.b-r-7 {
  border-radius: 7px;
}

.b-5 {
  border-radius: 5px !important;
}

/* Margin */
.m-0 {
  margin: 0px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-a-10 {
  margin: 10px;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

/* Padding */
.p-0 {
  padding: 0px !important;
}

.p-5 {
  padding: 5px;
}

.p-8 {
  padding: 8px;
}

.p-16 {
  padding: 16px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-l-5 {
  padding: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

/* Font size */
.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-16 {
  font-size: 16px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 30px;
}

/* Font weight */
.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.fw-bold {
  font-weight: bold;
}

/* Color */
.gray {
  color: gray !important;
}

.white {
  color: white !important;
}

.dark-blue {
  color: darkblue !important;
}

.red {
  color: red !important;
}

.black {
  color: black !important;
}

.yellow {
  color: rgb(235, 235, 52) !important;
}


/* background color */
.bg-lightgray {
  background-color: rgb(245, 242, 242) !important;
}

.bg-page {
  background-color: #f1f1f1;
}

.bg-aliceblue {
  background-color: aliceblue !important;
}

.bg-red {
  background-color: rgba(204, 11, 11, 0.788) !important;
}

.bg-dark-blue {
  background-color: darkblue !important;
}

.bg-dark-green {
  background-color: green !important;
}

/* .bg-page { background-color: lightgray;} * */

/* gap */
.g-3 {
  gap: 3px !important;
}

.g-5 {
  gap: 5px !important;
}
.g-20 {
  gap: 20px !important;
}

/* text align */
.align-right {
  text-align: right !important;
}

.text-align-center {
  text-align: center !important;
}

.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('https://upjao.ai/wp-content/themes/UpjaoTheme/1x/countryside-2326787.jpg');
  background-size: cover;
  /* background-image: url('https://upjao.ai/wp-content/themes/UpjaoTheme/1x/logo.png'); */
}

.text-line {
  display: flex;
  flex-direction: row;
}

.text-line:before,
.text-line:after {
  content: "";
  flex: 1 1;
  border-bottom: 2px solid gray;
  margin: auto;
}

.table-header {
  color: black;
  /* font-family:'Times New Roman', Times, serif; */
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 18px;
  font-weight: 700 !important;
  background-color: aliceblue;
  padding: 10 !important;
}

.cursor {
  cursor: pointer !important;
}


.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.align-center {
  align-items: center;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-custom {
  flex-direction: row;
}

.inline {
  display: inline;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

a {
  text-decoration: none;
}

.main-container {
  display: flex;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
/* .APP-background { background-color: #b34d00 !important;} */
.sidebar {
  /* background: rgb(0, 7, 61); */
  /* background: rgb(45, 51, 89); */
  color: white;
  min-height: 100vh;
  border-right: 1px solid lightgray;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 15px 10px; */
  /* background-color: #AD8B73; */
  color: rgb(34, 93, 153) !important;
}

.logo {
  font-size: 18px;
  line-height: 0;
}

.bars {
  width: 30px;
}

.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}

.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  color: white;
}

.routes {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.link {
  display: flex;
  color: rgb(34, 93, 153) !important;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  /* border-right: 4px solid #b34d00; */
  /* background: rgb(45, 51, 89); */
  background-color: rgb(34, 93, 153) !important;
  opacity: 0.9;
  color: white !important;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.active {
  border-right: 2px solid rgb(34, 93, 153) !important;
  /* background: rgb(45, 51, 89); */
  color: rgb(34, 93, 153) !important;
  background-color: lightgray;

}

.link_text {
  white-space: nowrap;
  font-size: 15px;
  text-decoration: none !important;
}

.menu {
  display: flex;
  color: white;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

.sticky {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  z-index: 1001;
}

.disable-column-header-focus:focus {
  outline: none !important;
}

.disable-cell-focus:focus {
  outline: none !important;
}

.custom-header-cell {
  font-weight: bold !important;
}

/* image slider */
.image-slider {
  display: flex;
  width: 100%;
  height: 100%;
}

.image-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  max-height: 100%;
  min-width: 20%;
  overflow-y: auto;
}

.image-item {
  margin-bottom: 10px;
  cursor: pointer;
}

.image-item.active {
  border: 2px solid blue;
}

.display-justify-start {
  width: 100%;
  display: flex;
  justify-content: start;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #fff;
  border: none;
  /* outline-width: 6; */
  font-size: 24px;
  cursor: pointer;
}

.prev-button {
  left: 20px;
}

.next-button {
  right: 20px;
}

.grid-template-columns {
  grid-template-columns: repeat(3, 1fr);
}

/* Responsive class */

.card-Width {
  width: 49.5%
}

.mq-borderLeft {
  border-left: 2px solid gray
}

.mq-dropdown-minwidth {
  width: 200px !important
}

.rs-right-margin {
  margin-right: 10px;
}

/* Media Query */

@media (max-width: 768px) {
  .grid-template-columns {
    grid-template-columns: repeat(1, 1fr);
  }

  .flex-direction-custom {
    flex-direction: column;
  }

  .m-top-10 {
    margin-top: 10px;
  }

  .card-Width {
    width: 100% !important
  }

  .mq-borderLeft {
    border: none;
  }

  .rs-width-100 {
    width: 100%;
  }

  .rs-right-margin {
    margin-right: 0px;
  }

  .rs-m-10 {
    margin: 10px;
  }

  .mq-dropdown-minwidth {
    width: 100% !important;
  }
}

/*  */

.slider-container {
  display: flex;
  align-items: center;
  /* This ensures that the text and slider are vertically centered */
}

.area-label {
  margin-right: 8px;
  /* Adjust the margin as needed */
}

.buttonStyle {
  background-color: rgb(34, 93, 153) !important;
  color: white !important
}
.resetButtonStyle {
  background-color: rgb(245, 141, 93) !important;
  color: white !important
}